/* html {
    --font-sans-serif: -apple-system, BlinkMacSystemFont, avenir next, avenir,
      segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    --font-serif: Iowan Old Style, Apple Garamond, Baskerville, Times New Roman,
      Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;
    font-family: var(--font-sans-serif);
  }
  
  html,
  body,
  #root {
    margin: 0;
    height: 100%;
  }
  
  .App {
    display: grid;
    min-height: 100%;
    place-content: center;
    background-image: linear-gradient(
      45deg,
      hsl(240deg 100% 62%) 0%,
      hsl(231deg 100% 61%) 11%,
      hsl(222deg 100% 59%) 22%,
      hsl(212deg 100% 58%) 33%,
      hsl(203deg 100% 57%) 44%,
      hsl(194deg 100% 55%) 56%,
      hsl(185deg 100% 54%) 67%,
      hsl(175deg 100% 53%) 78%,
      hsl(166deg 100% 51%) 89%,
      hsl(157deg 100% 50%) 100%
    );
  }
  
  .CheckIconContainer {
    --check-icon-width: 100px;
    --check-icon-height: 100px;
  
    width: var(--check-icon-width);
    height: var(--check-icon-height);
  }
  
  .CheckIcon {
    color: black;
    width: var(--check-icon-width);
    height: var(--check-icon-height);
  }
  
  .ToggleButton {
    color: black;
    cursor: pointer;
    font-size: 16px;
    background: none;
    margin-top: 16px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 8px;
    border: 2px solid black;
    -webkit-tap-highlight-color: transparent;
  }
   */

   /* SERVICES SECTION STARTS */
   
.service1:hover{
background-color: black;
}
.service1:hover .service1_icon{
  color: #CA8A04;
}

.service1:hover .service1_btn{
  background-color: #CA8A04;
}

.service1:hover h1{
  color: #CA8A04;
}

.service2:hover{
  background-color: black;
  }
  .service2:hover .service2_icon{
    color: #CA8A04;
  }
  .service2:hover h1{
    color: #CA8A04;
  }
  
  .service3:hover{
    background-color: black;
    }
    .service3:hover .service3_icon{
      color: #CA8A04;
    }
    .service3:hover h1{
      color: #CA8A04;
    }
.service3:hover{
    background-color: black;
    }
    .service3:hover .service3_icon{
      color: #CA8A04;
    }
    .service3:hover h1{
      color: #CA8A04;
    }
    .service4:hover{
      background-color: black;
      }
      .service4:hover .service4_icon{
        color: #CA8A04;
      }
      .service4:hover h1{
        color: #CA8A04;
      }

      .service5:hover{
        background-color: black;
        }
        .service5:hover .service5_icon{
          color: #CA8A04;
        }
        .service5:hover h1{
          color: #CA8A04;
        }
   /* SERVICES SECTION ENDS */

   /* TEAM SECTION STARTS */

.team1:hover{
  background-color: black;
}
.team1:hover div h2{
  color: #CA8A04;
}


   /* TEAM SECTION ENDS */

   /* COUNTER SECTION STARTS */

.counter1:hover{
  background-color: black;
}

.counter1:hover svg{
  color: #CA8A04;
}

.counter1:hover h2{
  color: #CA8A04;
}
.counter1:hover h2 div{
  color: #CA8A04;
}
.counter1:hover p{
  color: #CA8A04;
}



   /* COUNTER SECTION ENDS */

   /* faq starts */
   .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  .active, .accordion:hover {
    background-color: #ccc;
  }
  
  .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

   /* faq ends */



   .marquee_slide {
    width: 450px;
    line-height: 50px;
    /* background-color: red; */
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    padding-left: 100%;
    animation: marquee_slide 15s linear infinite;
}
@keyframes marquee_slide {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}
